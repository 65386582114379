import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
import { Image, Text } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <Image 
        w="175px"
        src="https://firebasestorage.googleapis.com/v0/b/homeappstpieter.appspot.com/o/logo-transparant.png?alt=media&token=b665b193-f500-41e1-9c91-5b469e6338af" 
      />
      <Text fontWeight="900" mb="5">HomeApp - Kim & Jasper</Text>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
