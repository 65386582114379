// Chakra imports
import {
  Box,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  Checkbox,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import Menu from "components/menu/MainMenu";
import IconBox from "components/icons/IconBox";

import BoodschappenlijstItem from "./BoodschappenlijstItem";
import db from "lib/firebase-db";
import { doc, onSnapshot, collection, getDocs, getDoc } from "firebase/firestore";

// Assets
import { MdCheckBox, MdDragIndicator } from "react-icons/md";
import React, { useEffect, useState } from "react";

export default function Conversion({items}) {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const [data, updateData] = useState([]);

    //Call when component is rendered
    useEffect(() => {
      const list = [];
      const unsubscribe = onSnapshot(collection(db, "boodschappen"), (colSnap) => {
        colSnap.docs.forEach((doc) => {
          list.push(doc.data());
        })
        updateData(list);
      });

      return () => unsubscribe();
    }, []);



  return (
    <Card p='20px' align='center' direction='column' w='100%'>
      <Flex alignItems='center' w='100%' mb='30px'>
        <IconBox me='12px' w='38px' h='38px' bg={boxBg} icon={<Icon as={MdCheckBox} color={brandColor} w='24px' h='24px' />}/>
        <Text color={textColor} fontSize='lg' fontWeight='700'>
          Boodschappenlijstje
        </Text>
      </Flex>
      <Box px='11px'>
        {items.map((val, key) => {
          return (<BoodschappenlijstItem text={val.text} docID={val.id}/>);
        })}
      </Box>
    </Card>
  );
}
