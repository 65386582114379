import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getDatabase } from 'firebase/database';
import * as  firebase from 'firebase/app';

import { firebaseConfig } from 'config/constants';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

let vapidKey = "BM-rVD6f2W_elrkBFwLmUNbemJtOF25nFJc4An25_9Pj8abTNcUFY2Lt97G8PICGIqiSKZMpcVh2WOLar77_Rzc";
const messaging = getMessaging(app);

Notification.requestPermission().then(function () {
    console.log("Permission Granted");
    return getToken(messaging, { vapidKey: vapidKey });
})
.then(function (token) {
    console.log(token);
})
.catch(function (err) {
    console.log(err);
})

onMessage(function (payload) {
    console.log("Message Received:", payload);
});

export default auth;
