import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdOutlineCookie,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdOutlineCollectionsBookmark,
  MdOutlineContactPage,
  MdOutlineFormatListBulleted,
  MdOutlineInsertPhoto,
  MdMovie,
  MdOutlineEmail,
} from 'react-icons/md';

import { IoMdHeartEmpty, IoMdFlower} from 'react-icons/io';

// Admin Imports
import MainDashboard from 'views/admin/default';
import BoodschappenBoard from 'views/admin/boodschappen';
import VerzamelingBoard from 'views/admin/verzameling';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import SignUpCentered from 'views/auth/signUp';
import adressen from 'views/admin/adressen';
import mail from 'views/admin/mail';

const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: 'Boodschappenlijst',
    layout: '/admin',
    path: '/boodschappen',
    icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
    component: BoodschappenBoard,
  },
  {
    name: 'Verzameling',
    layout: '/admin',
    path: '/verzameling',
    icon: <Icon as={MdOutlineCollectionsBookmark} width="20px" height="20px" color="inherit" />,
    component: VerzamelingBoard,
  },
  {
    name: 'Adressen',
    layout: '/admin',
    path: '/adressen',
    icon: <Icon as={MdOutlineContactPage} width="20px" height="20px" color="inherit" />,
    component: adressen,
  },
  {
    name: 'Bucketlist',
    layout: '/admin',
    path: '/bucketlist',
    icon: <Icon as={MdOutlineFormatListBulleted} width="20px" height="20px" color="inherit" />,
    component: adressen,
  },
  {
    name: 'Recepten',
    layout: '/admin',
    path: '/recepten',
    icon: <Icon as={MdOutlineCookie} width="20px" height="20px" color="inherit" />,
    component: adressen,
  },
  {
    name: 'Foto-zone',
    layout: '/admin',
    path: '/fotozone',
    icon: <Icon as={MdOutlineInsertPhoto} width="20px" height="20px" color="inherit" />,
    component: adressen,
  },
  {
    name: 'Verlanglijstjes',
    layout: '/admin',
    path: '/verlanglijstjes',
    icon: <Icon as={IoMdHeartEmpty} width="20px" height="20px" color="inherit" />,
    component: adressen,
  },
  {
    name: 'Planten',
    layout: '/admin',
    path: '/planten',
    icon: <Icon as={IoMdFlower} width="20px" height="20px" color="inherit" />,
    component: adressen,
  },
  {
    name: 'MovieDB',
    layout: '/admin',
    path: '/movies',
    icon: <Icon as={MdMovie} width="20px" height="20px" color="inherit" />,
    component: adressen,
  },
  {
    name: 'E-mail',
    layout: '/admin',
    path: '/mail',
    icon: <Icon as={MdOutlineEmail} width="20px" height="20px" color="inherit" />,
    component: mail,
  },
  {
    name: 'Profile',
    layout: '/admin',
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  /*{
    name: 'NFT Marketplace',
    layout: '/admin',
    path: '/nft-marketplace',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: 'Data Tables',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/data-tables',
    component: DataTables,
  },
  {
    name: 'Sign Up',
    layout: '/auth',
    path: '/sign-up',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUpCentered,
  },
  {
    name: 'RTL Admin',
    layout: '/rtl',
    path: '/rtl-default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: RTL,
  },*/
];

export default routes;
