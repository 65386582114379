import { Stack, Button, Box, Text, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Input, SimpleGrid } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import db from "lib/firebase-db";
import { collection, onSnapshot, addDoc } from "firebase/firestore";
import VerzamelingItem from "./components/VerzamelingItem";
import { useDisclosure } from "@chakra-ui/react";

export default function VerzamelingBoard() {

    const [data, updateData] = useState([]);

    const [img, updateImg] = useState("");
    const [name, updateName] = useState("");
    const [aankoop, updateAankoop] = useState("");
    const [verkoop, updateVerkoop] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleImg = (event) => {
        updateImg(event.target.value);
    }

    const handleName = (event) => {
        updateName(event.target.value);
    }

    const handleAankoop = (event) => {
        updateAankoop(event.target.value);
    }

    const handleVerkoop = (event) => {
        updateVerkoop(event.target.value);
    }

    const handleSave = (event) => {
        const docRef = addDoc(collection(db, "verzameling"), {
            afbeelding: img,
            inCollectie: true,
            naam: name,
            prijsBetaald: aankoop,
            waarde: verkoop
        })

        updateImg("");
        updateName("");
        updateAankoop("");
        updateVerkoop("");
      };

    //Call when component is rendered
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "verzameling"), (colSnap) => {
            const list = [];
            colSnap.docs.forEach((doc) => {
                let item = {
                    id: doc.id,
                    data: doc.data()
                }
                list.push(item);
            })
            updateData(list);
        });

        return () => unsubscribe();
    }, []);

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Modal Title</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input mb="5" value={name} onChange={handleName} placeholder="Naam" borderRadius="16px" />
                        <Input mb="5" value={img} onChange={handleImg} placeholder="Link Afbeelding" borderRadius="16px" />
                        <Input mb="5" value={aankoop} onChange={handleAankoop} placeholder="Aankoopprijs" type="number" step="0.01" min="0" borderRadius="16px" />
                        <Input mb="5" value={verkoop} onChange={handleVerkoop} placeholder="Waarde" type="number" step="0.01" min="0" borderRadius="16px" />
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>Close</Button>
                        <Button colorScheme="brand" onClick={handleSave}>
                            Opslaan
                        </Button>
                        
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Stack direction="row" spacing={2} align="center" >
                <Text>Nieuw item aan verzameling toevoegen?</Text>
                <Button variant="brand" onClick={onOpen}>Voeg item toe.</Button>
            </Stack>

            <SimpleGrid mt="5"  columns={{sm: 1, md: 2, lg: 3}} spacing={2}>
                {data.map((val, key) => {
                    return (<VerzamelingItem data={val.data} />);
                })}
            </SimpleGrid>
        </Box>
    )
}