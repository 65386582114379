import { Box, useColorModeValue } from "@chakra-ui/react";
import Boodschappenlijst from "views/admin/boodschappen/components/Boodschappenlijst";
import { Input } from "@chakra-ui/react";
import db from "lib/firebase-db";
import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { useState, useEffect } from "react";

export default function UserReports() {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const docRef = addDoc(collection(db, "boodschappen"), {
        text: event.target.value
      })
      event.target.value = "";
    }
  };

  const [data, updateData] = useState([]);

  //Call when component is rendered
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "boodschappen"), (colSnap) => {
        const list = [];
        colSnap.docs.forEach((doc) => {
            let item = {
                id: doc.id,
                text: doc.data().text
            }
          list.push(item);
        })
        updateData(list);
      });

    return () => unsubscribe();
  }, []);

  return (

    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Input marginBottom="20px" placeholder="Wat wil je toevoegen aan het lijstje? (enter om op te slaan)" onKeyDown={handleKeyDown} borderRadius="16px" />
      <Boodschappenlijst items={data} />
    </Box>
  );
}
