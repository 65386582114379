import { List, ListIcon, ListItem, Box, Text, Stack, Button, Link } from "@chakra-ui/react"
import { ExternalLinkIcon } from "@chakra-ui/icons"
import { MdSettings } from "react-icons/md"
import ColumnsTable from "../dataTables/components/ColumnsTable"

export default () => {
    const columnsDataColumns = [
        {
            Header: "EMAIL",
            accessor: "email",
        },
        {
            Header: "WACHTWOORD",
            accessor: "wachtwoord",
        },
        {
            Header: "OPMERKING",
            accessor: "opmerking",
        },
    ];
    const tableDataColumns = [
        {
          "email":"info@kimenjasper.be",
          "wachtwoord": "Roger1951", 
          "opmerking": "Mailbox voor samen.",
        },
        {
            "email":"reclame@kimenjasper.be",
            "wachtwoord": "Roger1951", 
            "opmerking": "Mailbox voor spam.",
          },
        {
            "email":"kim@kimenjasper.be",
            "wachtwoord": "Ikhouvanpaarden1", 
            "opmerking": "Mailbox voor Kim.",
          },
          {
            "email":"jasper@kimenjasper.be",
            "wachtwoord": "Roger1951", 
            "opmerking": "Mailbox voor Jasper.",
          },
    ];

    return (
        <Stack pt={{ base: "130px", md: "80px", xl: "80px" }} direction="column" spacing="5">
            <Box>
                <Text>Lokaal instellen?</Text>
                <List>
                    <ListItem>
                        <ListIcon as={MdSettings} color="green.500" />
                        SMTP-server:
                    </ListItem>
                    <ListItem>
                        <ListIcon as={MdSettings} color="green.500" />
                        Port:
                    </ListItem>
                </List>
            </Box>
            <Box>
                <Stack spacing="5" direction="row">
                    <Link href="https://web0110.zxcs.be/rainloop" isExternal >
                        Open in nieuw tabblad. (Rainloop) <ExternalLinkIcon mx="2px" />
                    </Link>
                    <Link href="https://web0110.zxcs.be/roundcube/" isExternal >
                        Open in nieuw tabblad. (Roundcube) <ExternalLinkIcon mx="2px" />
                    </Link>
                </Stack>
            </Box>
            <Box>
                <ColumnsTable columnsData={columnsDataColumns} tableData={tableDataColumns} />
            </Box>
            <Box>
                <iframe src="https://web0110.zxcs.be/rainloop" width="100%" height="700px"></iframe>
            </Box>
        </Stack>
    )
}