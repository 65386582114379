import { Avatar, Box, Flex, FormLabel, Icon, Select, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import React from "react";
import db from "lib/firebase-db";
import { onSnapshot, collection } from "firebase/firestore";
import { useState, useEffect } from "react";
import Boodschappenlijst from "../boodschappen/components/Boodschappenlijst";

export default function Boodschappen() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [data, updateData] = useState([]);

  //Call when component is rendered
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "boodschappen"), (colSnap) => {
        const list = [];
        colSnap.docs.forEach((doc) => {
            let item = {
                id: doc.id,
                text: doc.data().text
            }
          list.push(item);
        })
        updateData(list);
      });

    return () => unsubscribe();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
        <Boodschappenlijst items={data} />
        <MiniCalendar h='100%' minW='100%' selectRange={false} />
      </SimpleGrid>
    </Box>
    
  );
}
