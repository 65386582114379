import { Box, Stack, SimpleGrid, Modal, ModalOverlay, ModalCloseButton, Input, Button, ModalBody, ModalContent, ModalFooter, ModalHeader, Text } from "@chakra-ui/react"
import Banner from "./components/Banner"
import { useState, useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import db from "lib/firebase-db";
import { onSnapshot, collection, addDoc } from "firebase/firestore";
import { Textarea } from "@chakra-ui/react";

export default () => {

    const [data, updateData] = useState([]);

    
    const [fname, updateFname] = useState("");
    const [lname, updateLname] = useState("");
    const [mail, updateMail] = useState("");
    const [phone, updatePhone] = useState("");

    const [street, updateStreet] = useState("");
    const [houseNr, updateHouseNr] = useState("");
    const [zip, updateZip] = useState("");
    const [place, updatePlace] = useState("");

    const [members, updateMembers] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleFName = (event) => {
        updateFname(event.target.value);
    }

    const handleLName = (event) => {
        updateLname(event.target.value);
    }

    const handleMail = (event) => {
        updateMail(event.target.value);
    }

    const handlePhone = (event) => {
        updatePhone(event.target.value);
    }

    const handleStreet = (event) => {
        updateStreet(event.target.value);
    }

    const handleHousenr = (event) => {
        updateHouseNr(event.target.value);
    }

    const handleZip = (event) => {
        updateZip(event.target.value);
    }

    const handlePlace = (event) => {
        updatePlace(event.target.value);
    }

    const handleMembers = (event) => {
        updateMembers(event.target.value);
    }

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "adressen"), (colSnap) => {
            const list = [];
            colSnap.docs.forEach((doc) => {
                let item = {
                    id: doc.id,
                    data: doc.data()
                }
                list.push(item);
            })
            updateData(list);
        });

        return () => unsubscribe();
    }, []);

    const handleSave = (event) => {
        let family = [];

        members.split(",").map((person) => {
            let trimmed = person.trim();
            let info = trimmed.split(" ");
            family.push({
                Voornaam: info[0].trim(),
                Familienaam: info[1].trim()
            })
        })

        let toPush = {
            Voornaam: fname,
            Familienaam: lname,
            Email: mail,
            Telefoonnummer: phone,
            Adres: {
                Straat: street,
                Huisnummer: houseNr,
                Postcode: zip,
                Gemeente: place
            },
            Gezinsleden: family
        };


        const docRef = addDoc(collection(db, "adressen"), toPush);

        updateFname("");
        updateLname("");
        updateMail("");
        updatePhone("");

        updateStreet("");
        updateHouseNr("");
        updatePlace("");
        updateZip("");

        updateMembers("");
      };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Modal Title</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input mb="5" value={fname} onChange={handleFName} placeholder="Voornaam" borderRadius="16px" />
                        <Input mb="5" value={lname} onChange={handleLName} placeholder="Familienaam" borderRadius="16px" />
                        <Input mb="5" value={mail} type="email" onChange={handleMail} placeholder="E-mailadres" borderRadius="16px" />
                        <Input mb="5" value={phone} onChange={handlePhone} placeholder="Telefoonnummer" borderRadius="16px" />
                        <Box mb="5">
                            <Text fontSize="sm">Adres</Text>
                            <hr />
                        </Box>
                        <Input mb="5" value={street} onChange={handleStreet} placeholder="Straat" borderRadius="16px" />
                        <Input mb="5" value={houseNr} type="number" step="1" min="1" onChange={handleHousenr} placeholder="Huisnummer" borderRadius="16px" />
                        <Input mb="5" value={zip} onChange={handleZip} placeholder="Postcode" borderRadius="16px" />
                        <Input mb="5" value={place} onChange={handlePlace} placeholder="Gemeente" borderRadius="16px" />
                        <Box mb="5">
                            <Text fontSize="sm">Gezinsleden (gescheiden door een komma)</Text>
                            <hr />
                        </Box>
                        <Textarea mb="5"  value={members} onChange={handleMembers} placeholder="Jakke Goegebeur, Jasper Goegebeur" borderRadius="16px"> </Textarea>
                   </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>Close</Button>
                        <Button colorScheme="brand" onClick={handleSave}>
                            Opslaan
                        </Button>
                        
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Stack direction="row" spacing={2} align="center" >
                <Text>Nieuw adres toevoegen?</Text>
                <Button variant="brand" onClick={onOpen}>Voeg item toe.</Button>
            </Stack>
            <SimpleGrid mt="5"  columns={{sm: 1, md: 2, lg: 3}} spacing={2}>
            {data.map((val, key) => {
                    return (<Banner data={val.data} />);
                })}
            </SimpleGrid>
        </Box>
    )
}