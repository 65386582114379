import { Box, Image, Text } from "@chakra-ui/react";

export default function (props) {
    return (
        <Box backgroundColor="white" maxW="sm" overflow="hidden" borderWidth="1px" borderRadius="lg">
            <Image src={props.data.afbeelding} height="270" margin="auto" alt="" />
            <Box p="6">
                <Text fontWeight="900">{props.data.naam}</Text>
                <Text>Aankoopprijs: {props.data.prijsBetaald}</Text>
                <Text>Waarde: {props.data.waarde}</Text>
            </Box>
        </Box>
    )
}