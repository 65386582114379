import {
    Box,
    Flex,
    Text,
    Icon,
    useColorModeValue,
    Checkbox,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/card/Card.js";
  import Menu from "components/menu/MainMenu";
  import IconBox from "components/icons/IconBox";
  import db from "lib/firebase-db";
  import { ref, onValue} from "firebase/database";

  import { MdCheckBox, MdDragIndicator } from "react-icons/md";
import { deleteDoc, doc } from "firebase/firestore";

export default function(props) {
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
    const brandColor = useColorModeValue("brand.500", "brand.400");

    const handleChange = (event) => {
        if(event.target.checked) {
            event.target.checked = true;
            deleteDoc(doc(db, "boodschappen", event.target.id));
        }
    }

    return (
        <Flex >
        <Checkbox isChecked={false} me='16px' id={props.docID} colorScheme='brandScheme' onChange={handleChange} />
        <Text
          fontWeight='bold'
          color={textColor}
          fontSize='md'
          textAlign='start'>
          {props.text}
        </Text>
        <Icon
          ms='auto'
          as={MdDragIndicator}
          color='secondaryGray.600'
          w='24px'
          h='24px'
        />
      </Flex>
    );
}