export const firebaseConfig = {
  apiKey: "AIzaSyD2E5w758thU8qudG1bmSH4PhbLm7QsMQc",
  authDomain: "homeappstpieter.firebaseapp.com",
  databaseURL: "https://homeappstpieter-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "homeappstpieter",
  storageBucket: "homeappstpieter.appspot.com",
  messagingSenderId: "904295830906",
  appId: "1:904295830906:web:b964155c052b64da7ff2c3",
  measurementId: "G-DFGDYN3RNG"
};
