// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function Banner(props) {
  let gezinsleden = "";

  if(props.data !== undefined && props.data !== null) {
    if(props.data.Gezinsleden !== undefined && props.data.Gezinsleden !== null) {
      props.data.Gezinsleden.map((item) => {
        gezinsleden += item.Voornaam + " " + item.Familienaam + ", ";
      });
  
      gezinsleden = gezinsleden.substring(0, gezinsleden.length - 2);
    }
  }
  

  
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  console.log(props.data);
  return (  
    <Card mb={{ base: "0px", lg: "20px" }} mt="5" align='center'>
      <Avatar mx='auto' src={props.data.avatar} h='87px' w='87px' mt='-43px' border='4px solid' borderColor={borderColor} />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {props.data.Familienaam} {props.data.Voornaam}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {gezinsleden}
      </Text>
      <Text color={textColorPrimary} fontSize='md' mt='10px'>
        Adres: {props.data.Adres.Straat} {props.data.Adres.Huisnummer}, {props.data.Adres.Postcode} {props.data.Adres.Gemeente}
      </Text>
      <Text color={textColorPrimary} fontSize='md' mt='10px'>
        E-mailadres: {(props.data.Email !== undefined && props.data.Email !== null && props.data.Email !== "") ? props.data.Email : "N/A"}
      </Text>
      <Text color={textColorPrimary} fontSize='md' mt='10px'>
        Telefoonnummer: {(props.data.Telefoonnummer !== undefined && props.data.Telefoonnummer !== null && props.data.Telefoonnummer !== "") ? props.data.Telefoonnummer : "N/A"}
      </Text>
    </Card>
  );
}
